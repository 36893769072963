<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<style scoped>
.card {
  padding: 2rem;
  margin: 0 auto;
  max-width: 1280px;
}

@media (max-width: 768px) {
  .card {
    margin: 0;
  }
}
</style>
