<template>
  <div>
    <base-button
      :class="this.filters.all ? null : 'outline'"
      id="all"
      @click="setFilter('all')"
      >All</base-button
    >
    <base-button
      :class="this.filters.ux_ui ? null : 'outline'"
      id="ux_ui"
      @click="setFilter('ux_ui')"
      >UX/UI</base-button
    >
    <base-button
      :class="this.filters.interactive ? null : 'outline'"
      id="interactive"
      @click="setFilter('interactive')"
      >Interactive</base-button
    >
    <!--     <base-button
      :class="this.filters.code ? null : 'outline'"
      id="code"
      @click="setFilter('code')"
      >code</base-button
    > -->
    <base-button
      :class="this.filters.video ? null : 'outline'"
      id="video"
      @click="setFilter('video')"
      >video</base-button
    >
  </div>
</template>

<script>
import BaseButton from "./BaseButton.vue";
export default {
  components: { BaseButton },
  emits: ["change-filter"],
  data() {
    return {
      filters: {
        all: true,
        ux_ui: false,
        interactive: false,
        code: false,
        video: false,
      },
    };
  },
  methods: {
    setFilter(id) {
      const updatedFilters = { ...this.filters };
      /*       const updatedFilters = {
        ...this.filters,
        [id]: !this.filters[id],
      }; */
      Object.keys(updatedFilters).forEach((key) => {
        if (key == id) {
          updatedFilters[key] = true;
        } else {
          updatedFilters[key] = false;
        }
      });
      this.filters = updatedFilters;
      this.$emit("change-filter", updatedFilters);
    },
  },
};
</script>

<style scoped></style>
