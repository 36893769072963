<template>
  <the-header></the-header>
  <main>
    <router-view v-slot="slotProps" :key="$route.path">
      <transition name="route" mode="out-in">
        <component :is="slotProps.Component"> </component>
      </transition>
    </router-view>
  </main>
  <the-footer></the-footer>
</template>

<script>
import TheHeader from "./components/TheHeader.vue";
import TheFooter from "./components/TheFooter.vue";

export default {
  name: "App",
  components: { TheHeader, TheFooter },
  provide() {
    return {
      projects: this.projects,
    };
  },
};
</script>

<style>
@font-face {
  font-family: "PPAgrandir";
  src: local("PPAgrandir"),
    url(./fonts/PPAgrandir-Variable.ttf) format("truetype");
}
:root {
  /*   --main-black: #262626; */
  --main-black: #2c3e50;
  --secondary: #044bd9;
  --grey: rgb(228, 228, 228);
}
h1 {
  font-variation-settings: "wght" 500, "ital" 0, "wdth" 200;
  font-size: 3rem;
  margin: 0;
  transition: all 0.25s ease-out;
}
h2 {
  font-size: 2rem;
  font-variation-settings: "wght" 300, "ital" 0, "wdth" 120;
}
h3 {
  font-size: 1.5rem;
  font-variation-settings: "wght" 500, "ital" 0, "wdth" 120;
}
h4 {
  font-size: 1.2rem;
  font-variation-settings: "wght" 500, "ital" 0, "wdth" 120;
}
p {
  font-size: 1.5rem;
  margin: 0;
  font-variation-settings: "wght" 400, "ital" 0, "wdth" 100;
}
span {
  font-size: 1.2rem;
  margin: 0;
  font-variation-settings: "wght" 400, "ital" 0, "wdth" 100;
}
p a {
  color: var(--secondary);
  text-decoration: none;
  transition: all 0.25s ease-out;
}
p a:hover {
  font-variation-settings: "wght" 500, "ital" 10, "wdth" 120;
}
* {
  box-sizing: border-box;
}
#app {
  font-family: PPAgrandir, Helvetica, Arial, sans-serif;
  color: var(--main-black);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
body,
html {
  margin: 0;
}
ul {
  list-style: none;
}
.route-enter-from,
.route-enter-leave-to {
  opacity: 0;
}
.route-enter-active {
  transition: opacity 0.2s ease-out;
}
.route-leave-active {
  transition: opacity 0.2s ease-in;
}
@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }
}
</style>
