<template>
  <base-card>
    <div class="not-found">
      <h2>Not found...</h2>
      <router-link to="/home"
        ><base-button>Go to the home page</base-button></router-link
      >
    </div>
  </base-card>
</template>

<script>
import BaseCard from "@/components/UI/BaseCard.vue";
import BaseButton from "@/components/UI/BaseButton.vue";
export default {
  components: { BaseCard, BaseButton },
};
</script>

<style scooped>
.not-found {
  text-align: center;
}
</style>
