<template>
  <section>
    <base-card class="card">
      <h2>You can download my cv here</h2>
      <base-button @click="openPdf">download</base-button>
    </base-card>
  </section>
</template>

<script>
export default {
  methods: {
    openPdf() {
      window.open("/cv.pdf", "_blank", "fullscreen=yes");
      return false;
    },
  },
};
</script>

<style scoped>
.card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.card h1 {
  text-align: center;
}
</style>
