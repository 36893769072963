<template>
  <section>
    <base-card class="intro">
      <h2>
        Hi! I'm
        <span id="me">Aurélien</span>
        <br />
        <br />
        I’m a <span>media & interaction Designer</span>, I like to make artistic
        and creative stuff using programming and digital media.<br />
        Here are some of my projects
      </h2></base-card
    >
    <base-card class="filters-card">
      <div class="filters">
        <project-filter @change-filter="setFilters"></project-filter>
      </div>
    </base-card>
    <base-card>
      <transition-group tag="ul" name="project-list" v-if="hasProjects">
        <project-item
          v-for="project in filterProjects"
          :key="project.id"
          :title="project.name"
          :year="project.year"
          :tag="project.tag"
          :id="project.id"
          :img="project.cardImg"
          :color="project.titleColor"
        ></project-item>
      </transition-group>
      <h3 v-else>No projects found...</h3>
    </base-card>
  </section>
</template>

<script>
import ProjectItem from "../components/ProjectItem.vue";
import ProjectFilter from "../components/UI/ProjectFilter.vue";

export default {
  components: { ProjectItem, ProjectFilter },
  data() {
    return {
      activeFilters: {
        ux_ui: true,
        interactive: true,
        code: true,
        video: true,
      },
    };
  },
  computed: {
    filterProjects() {
      const projects = this.$store.getters["projectsData/projects"];
      return projects.filter((proj) => {
        if (this.activeFilters.all) {
          return true;
        }
        if (this.activeFilters.ux_ui && proj.tag.includes("ux_ui")) {
          return true;
        }
        if (
          this.activeFilters.interactive &&
          proj.tag.includes("interactive")
        ) {
          return true;
        }
        if (this.activeFilters.code && proj.tag.includes("code")) {
          return true;
        }
        if (this.activeFilters.video && proj.tag.includes("video")) {
          return true;
        }
        return false;
      });
    },
    hasProjects() {
      return this.$store.getters["projectsData/hasProjects"];
    },
  },
  methods: {
    setFilters(updatedFilters) {
      this.activeFilters = updatedFilters;
    },
  },
};
</script>

<style scoped>
.intro {
  margin-top: 2rem;
  margin-bottom: 4rem;
}
h2 span {
  font-size: 2rem;
  font-variation-settings: "wght" 300, "ital" 0, "wdth" 120;
  color: var(--secondary);
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row;
}
.filters-card {
  padding: 0 2rem;
}
.filters {
  display: flex;
  justify-content: end;
  align-items: center;
}
.images {
  position: absolute;
  transition: transform 1s ease-out;
  max-width: 300px;
}

.project-list-enter-from,
.project-list-leave-to {
  opacity: 0;
}
.project-list-enter-active {
  transition: all 0.4s ease-out;
}
.project-list-leave-active {
  transition: all 0.3s ease-in;
}
.project-list-enter-to,
.project-list-leave-from {
  opacity: 1;
}

#me-img {
  width: 200px;
  opacity: 0;
  position: absolute;
  pointer-events: none;
}

@media (max-width: 768px) {
  ul {
    grid-template-columns: 1fr;
  }
  .filters {
    flex-direction: column;
  }
}
</style>
