<template>
  <header>
    <nav class="nav-menu">
      <div
        class="nav-content"
        :class="this.showMobileMenu ? 'open-menu' : 'closed-menu'"
      >
        <router-link @click="showMenu()" to="/home" class="logo-link"
          ><img class="logo" src="@/assets/img/favicon.png"
        /></router-link>
        <ul class="nav-items">
          <li>
            <router-link @click="showMenu()" to="/about"
              ><h2>about</h2></router-link
            >
          </li>
          <li>
            <router-link @click="showMenu()" to="/resume"
              ><h2>resume</h2></router-link
            >
          </li>
        </ul>
      </div>
      <div
        class="hamburger-lines"
        :class="this.showMobileMenu ? 'open' : 'closed'"
        @click="showMenu()"
      >
        <span class="line line1"></span>
        <span class="line line2"></span>
        <span class="line line3"></span>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      showMobileMenu: false,
    };
  },
  methods: {
    showMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
  },
};
</script>

<style scoped>
header {
  width: 100%;
  height: 7rem;
  display: flex;
  justify-content: space-between;
}
header a {
  text-decoration: none;
  color: var(--main-black);
  display: inline-block;
  padding: 0rem 1.5rem;
  border: 1px solid transparent;
}
header h2 {
  transition: all 0.1s ease-out;
}
header h2:hover {
  font-variation-settings: "wght" 600, "ital" 10, "wdth" 120;
}
li:last-child a {
  padding-right: 0;
}

header nav {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}

header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
a:hover,
a:active,
a.active {
  color: var(--secondary);
}
.logo {
  width: 80px;
  padding: 0;
}
.logo-link {
  padding: 0;
}

.nav-menu {
  background-color: white;
}
.nav-content {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  align-items: center;
}
.nav-items {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
.nav-item li {
  padding: 0 10px;
}

.hamburger-lines {
  display: none;
  height: 26px;
  width: 32px;
  position: absolute;
  top: 17px;
  left: 2rem;
  z-index: 999;
  flex-direction: column;
  justify-content: space-between;
}

.hamburger-lines .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: var(--main-black);
}

@media screen and (max-width: 768px) {
  header {
    height: 4rem;
  }
  li:last-child a {
    padding-right: 1.5rem;
  }
  .nav-menu {
    padding-top: 10px;
    position: absolute;
    width: 100%;
  }
  .open-menu {
    opacity: 1;
  }
  .closed-menu {
    opacity: 0;
    height: 0;
    padding: 0;
    pointer-events: none;
  }
  .nav-content {
    flex-direction: column;
    z-index: 100;
    position: relative;
    transition: all 0.2s ease-out;
    width: 100%;
    z-index: 999;
    background-color: white;
  }
  .nav-items {
    flex-direction: column;
  }
  .hamburger-lines {
    display: flex;
  }
  .open .line1 {
    transform: translate(0px, 11px) rotate(45deg);
  }
  .open .line2 {
    transform: scale(0);
  }
  .open .line3 {
    transform: translate(0px, -11px) rotate(-45deg);
  }
}
</style>
