<template>
  <section>
    <base-card>
      <div class="about-grid">
        <div class="img">
          <img :src="require(`@/assets/${aboutMe.img}`)" alt="" />
        </div>
        <div class="desc">
          <h1>{{ aboutMe.title }}</h1>
          <p v-html="aboutMe.description"></p>
        </div>
      </div>
    </base-card>
  </section>
</template>

<script>
export default {
  computed: {
    aboutMe() {
      const about = this.$store.getters["projectsData/about"];
      return about;
    },
  },
};
</script>

<style scoped>
.about-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 2rem;
}
.img {
  width: 300px;
}
.img img {
  width: 100%;
  border-radius: 32px;
}
h1 {
  margin-top: 0;
}
@media (max-width: 768px) {
  .about-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}
</style>
