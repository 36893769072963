<template>
  <section class="bg-footer">
    <base-card>
      <div class="footer-grid">
        <div>
          <h4>Contact me</h4>
          <a href="mailto:contact@aurelienpellegrini.ch"
            >contact@aurelienpellegrini.ch</a
          >
        </div>
        <div>
          <h4>Find me here</h4>
          <a href="https://www.instagram.com/aurelien_pellegrini/">Instagram</a>
          <a href="https://vimeo.com/pellegriniecal">Vimeo</a>
          <a href="mailto: contact@aurelienpellegrini.ch">email</a>
        </div>
      </div>
    </base-card>
  </section>
</template>

<style scoped>
.bg-footer {
  background-color: var(--grey);
  margin-bottom: 0;
  margin-top: auto;
}
.card {
  margin-bottom: 0;
}
.footer-grid {
  display: grid;
  grid-template-columns: 300px 300px;
}
.footer-grid div {
  display: flex;
  flex-direction: column;
}
.footer-grid div:last-child {
  margin-bottom: 2rem;
}
@media (max-width: 768px) {
  .footer-grid {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
  }
}

a {
  text-decoration: none;
  color: grey;
}
a:hover {
  color: var(--secondary);
}
</style>
