<template>
  <button :type="type" :class="mode">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: ["type", "mode"],
};
</script>

<style scoped>
button {
  padding: 0.5rem 2rem;
  max-height: 3.5rem;
  margin: 0.4rem;
  font-family: PPAgrandir, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  background-color: var(--main-black);
  color: white;
  cursor: pointer;
  border-radius: 999px;
  border: none;
  transition: all 0.25s ease-out;
}
button:hover,
button:active {
  background-color: var(--secondary);
}
.outline {
  background-color: var(--grey);
  color: var(--main-black);
}
.outline:hover,
.outline:active {
  background-color: var(--secondary);
  color: white;
}
</style>
