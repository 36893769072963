<template>
  <div @click="closeDialog"></div>
  <dialog open @click="closeDialog">
    <a @click="closeDialog" class="cross"></a>
    <img :src="require(`@/assets/${img}`)" class="img-fullscreen" />
  </dialog>
</template>

<script>
export default {
  props: ["img", "close"],
  emits: ["close"],
  methods: {
    closeDialog() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
div {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}
dialog {
  position: fixed;
  top: 5vh;
  left: 5vw;
  width: 90%;
  height: 90%;
  z-index: 100;
  border-radius: 16px;
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
}
@media (max-width: 768px) {
  dialog {
    width: 100%;
    height: 100%;
    left: 0;
  }
  .cross {
    margin: 10px;
  }
}
.img-fullscreen {
  max-width: 100%;
  max-height: 100%;
  border-radius: 16px;
  object-fit: contain;
}
.cross {
  position: absolute;
  right: 0;
  top: -1rem;
  width: 32px;
  height: 32px;
  opacity: 1;
  cursor: pointer;
}
.cross:hover {
  opacity: 1;
}
.cross:before,
.cross:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 33px;
  width: 2px;
  background-color: white;
}
.cross:before {
  transform: rotate(45deg);
}
.cross:after {
  transform: rotate(-45deg);
}
</style>
