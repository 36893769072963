<template>
  <section class="header">
    <image-dialog
      v-if="showImageDialog"
      :img="this.imageToShowInDialog"
      @close="closeDialog"
    >
    </image-dialog>
    <base-card>
      <div class="title">
        <h1>{{ name }}</h1>
        <br />
        <p>{{ this.selectedProject.year }}</p>
      </div>

      <div class="media-top">
        <div v-if="vimeo" style="padding: 56.25% 0 0 0; position: relative">
          <iframe
            v-bind:src="vimeo"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            title="0"
            controls="0"
          ></iframe>
        </div>
        <img v-else :src="require(`@/assets/${media}`)" />
      </div>
      <div class="desc">
        <p v-html="description"></p>
        <br />
        <h3>Technology</h3>
        <span>{{ this.selectedProject.technology }}</span>
      </div>
      <div class="gallery">
        <ul :style="gridStyle" class="card-list">
          <li
            v-for="(card, index) in this.selectedProject.img"
            class="card-item"
            :key="index"
          >
            <img
              :src="require(`@/assets/${card}`)"
              alt=""
              @click="imgClick(card)"
              style="cursor: pointer"
            />
          </li>
        </ul>
      </div>
      <div class="next-project">
        <router-link :to="nextProject" @click="scrollToTop"
          ><base-button>See an other project</base-button></router-link
        >
      </div>
    </base-card>
  </section>
</template>

<script>
export default {
  props: ["workID"],
  data() {
    return {
      selectedProject: null,
      numberOfColumns: null,
      windowX: window.innerWidth,
      showImageDialog: false,
      imageToShowInDialog: "",
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    imgClick(item) {
      this.imageToShowInDialog = item;
      this.showImageDialog = true;
    },
    closeDialog() {
      this.showImageDialog = false;
    },
  },
  computed: {
    name() {
      return this.selectedProject.name;
    },
    description() {
      return this.selectedProject.description;
    },
    vimeo() {
      return this.selectedProject.vimeo;
    },
    media() {
      return this.selectedProject.media;
    },
    gridStyle() {
      if (this.windowX > 768) {
        return {
          gridTemplateColumns: `repeat(${this.numberOfColumns}, minmax(100px, 1fr))`,
        };
      } else {
        return {
          gridTemplateColumns: `repeat(1, minmax(100px, 1fr))`,
        };
      }
    },
    nextProject() {
      const projects = this.$store.getters["projectsData/projects"];
      const index = projects.findIndex((work) => work === this.selectedProject);
      let nextProjectIndex;
      if (projects.length > index + 1) {
        nextProjectIndex = index + 1;
      } else {
        nextProjectIndex = 0;
      }
      return "/project_" + projects[nextProjectIndex].id;
    },
  },
  created() {
    const selectedWork = this.$store.getters["projectsData/projects"].find(
      (work) => work.id === this.workID
    );

    if (!selectedWork) {
      /* redirect to home if ID not exist */
      this.$router.push({ path: "/home" });
      return;
    }

    if (selectedWork.img) {
      const nbrImg = selectedWork.img.length;
      if (nbrImg <= 3) {
        this.numberOfColumns = nbrImg;
      } else {
        this.numberOfColumns = 3;
      }
    }

    /*TODO to avoid creating element if id is false check condition in router */
    this.selectedProject = selectedWork;
  },
  mounted() {
    window.onresize = () => {
      this.windowX = window.innerWidth;
    };
  },
};
</script>

<style scoped>
ul {
  padding: 0;
}
.gallery img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

.card-list {
  margin: 2rem 0;
  display: grid;
  gap: 1em;
}
.title {
  margin: auto;
  max-width: 700px;
  text-align: center;
}
.media-top {
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  text-align: center;
  margin: 2rem 0;
}
.media-top img {
  max-height: 43rem;
  max-height: auto;
  border-radius: 16px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
}
.vp-controls-wrapper {
  display: none;
}
.desc {
  max-width: 700px;
  margin: auto;
}
.next-project {
  justify-content: center;
  display: flex;
  margin: 4rem 0;
}
</style>
