import { createStore } from "vuex";

import projectsModule from "./modules/index.js";

const store = createStore({
  modules: {
    projectsData: projectsModule,
  },
});

export default store;
