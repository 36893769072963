<template>
  <router-link :to="linkToWorkPage" @mouseover="mouseOver" @click="scrollToTop">
    <li :style="backgroundImageInlineStyle" class="project-card">
      <h1 :class="color">{{ title }}</h1>
      <div class="project-info">
        <span :class="color">{{ year }}</span>
        <span :class="color">{{ tag == "ux_ui" ? "UX/UI" : tag }}</span>
      </div>
    </li>
  </router-link>
</template>

<script>
export default {
  props: ["id", "title", "year", "tag", "img", "color"],
  emits: ["mouseOverProject"],
  computed: {
    linkToWorkPage() {
      return "/project_" + this.id;
    },
    backgroundImageInlineStyle() {
      const link = require(`@/assets/${this.img}`);
      return "background-image: url(" + link + ")";
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    mouseOver() {
      this.$emit("mouseOverProject", this.id);
    },
  },
};
</script>

<style scoped>
.project-info {
  display: flex;
}
.project-info span:last-child {
  margin: 0 0 0 20px;
}
.project-card {
  height: 34rem;
  widows: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 16px;
  padding: 1.5rem;
  transition: all 0.45s ease-out;
  /*   box-shadow: 15px 7px 80px rgba(0, 0, 0, 0.13); */
}
.project-card:hover {
  transform: scale(1.02);
}
.project-card:hover h1 {
  font-variation-settings: "wght" 800, "ital" 10, "wdth" 200;
}
.white {
  color: white;
}
.black {
  color: var(--main-black);
}
a {
  text-decoration: none;
  color: #000;
}
li {
  padding: 1rem 0;
}

h3 {
  font-size: 1.5rem;
}

h3,
h4 {
  margin: 0.5rem 0;
}
</style>
