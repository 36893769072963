import { createApp } from "vue";

import BaseButton from "./components/UI/BaseButton.vue";
import BaseCard from "./components/UI/BaseCard.vue";
import router from "./router.js";
import store from "./store/index.js";
import ImageDialog from "./components/UI/ImageDialog.vue";
import App from "./App.vue";

const app = createApp(App);

app.use(router);
app.use(store);

/* import base-button globaly */
app.component("image-dialog", ImageDialog);
app.component("base-button", BaseButton);
app.component("base-card", BaseCard);

router.isReady().then(function () {
  app.mount("#app");
});
